import React from 'react';
import locationIcon from '../../assets/icons/location.svg';
import { calculateDistance } from '../../helper/racqyHelper';
import GetLocation from '../../hooks/getLocation';
import profileIcon from '../../assets/icons/profile-icon.svg';

const UserDetailMobile = ({ profileData, totalSports }) => {
  const { gpsLocation } = GetLocation();

  const calculateAge = (birthday) => {
    const dob = typeof birthday == 'string' ? new Date(birthday) : birthday; //new Date(birthday)
    var diff_ms = Date.now() - dob?.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-start">
        {profileData?.profilePictureStorageUrl ? (
          <img
            src={profileData?.profilePictureStorageUrl}
            className="w-24 h-24 rounded-full"
          />
        ) : (
          <div className="flex items-center justify-center bg-racqy-light-blue w-20 h-20 rounded-full">
            <img src={profileIcon} className="w-12 h-12" />
          </div>
        )}
        {/* User age, sports and number of friends */}
        <div className="flex items-end justify-between w-[60%] ml-12">
          <div className="flex flex-col items-center">
            <h6 className="font-sans font-semibold text-lg">
              {profileData?.birthday
                ? calculateAge(profileData?.birthday)
                : '-'}
            </h6>
            <h6 className="font-sans font-medium">Age</h6>
          </div>
          <div className="flex flex-col items-center">
            <h6 className="font-sans font-semibold text-lg">
              {profileData?.friendsCount}
            </h6>
            <h6 className="font-sans font-medium">Friends</h6>
          </div>
          <div className="flex flex-col items-center">
            <h6 className="font-sans font-semibold text-lg">{totalSports}</h6>
            <h6 className="font-sans font-medium">Sports</h6>
          </div>
        </div>
      </div>
      {/* User's name, username and distance */}
      <div className="flex flex-col items-start mt-3">
        {/* <h6 className="font-sans font-medium">{`@${profileData?.username}`}</h6> */}
        <h6 className="font-sans font-bold">
          {`${profileData?.name?.full}`}
          <span className="font-medium">{` (@${profileData?.username})`}</span>
        </h6>
        <div className="flex items-center">
          <img src={locationIcon} />
          <h6 className="font-sans text-racqy-blue text-xs">{`${calculateDistance(
            {
              lat: gpsLocation?.latitude,
              lng: gpsLocation?.longitude,
            },
            {
              ...profileData?.lastLocation,
            }
          )}km away`}</h6>
        </div>
        {/* Description */}

        <p className="text-base font-sans text-racqy-settings-label font-light mt-2">
          {profileData?.bio}
        </p>
      </div>
    </div>
  );
};

export default UserDetailMobile;
