import React from 'react';
import locationIcon from '../../assets/icons/location.svg';
import { calculateDistance } from '../../helper/racqyHelper';
import GetLocation from '../../hooks/getLocation';
import profileIcon from '../../assets/icons/profile-icon.svg';

const UserDetail = ({ profileData, totalSports }) => {
  const { gpsLocation } = GetLocation();

  const calculateAge = (birthday) => {
    const dob = typeof birthday == 'string' ? new Date(birthday) : birthday; //new Date(birthday)
    var diff_ms = Date.now() - dob?.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const handleDistance = (loc1, loc2) => {
    let distance = calculateDistance(loc1, loc2);

    if (!distance) {
      return '';
    } else {
      return distance;
    }
  };

  return (
    <div className="flex">
      {profileData?.profilePictureStorageUrl ? (
        <img
          src={profileData?.profilePictureStorageUrl}
          className="lg:w-36 lg:h-36 sm:max-lg:w-24 sm:max-lg:h-24 rounded-full"
        />
      ) : (
        <div className="flex items-center justify-center bg-racqy-light-blue lg:w-36 lg:h-36 sm:max-lg:w-24 sm:max-lg:h-24 rounded-full">
          <img
            src={profileIcon}
            className="lg:w-24 lg:h-24 sm:max-lg:w-14 sm:max-lg:h-14"
          />
        </div>
      )}
      <div className="ml-12 flex flex-col justify-between lg:w-[600px] sm:max-lg:w-[500px]">
        {/* User age, sports and number of friends section */}
        <div className="flex">
          {/* User's name, username and distance */}
          <div className="flex flex-col items-start w-[40%]">
            <h2 className="font-sans font-medium lg:text-2xl sm:max-lg:text-lg">{`@${
              profileData?.username ? profileData?.username : ''
            }`}</h2>
            <h6 className="font-sans font-bold lg:text-base sm:max-lg:text-sm">
              {profileData?.name?.full}
            </h6>
            <div className="flex items-center">
              <img src={locationIcon} />
              <h6 className="font-sans text-racqy-blue text-xs">{`${handleDistance(
                {
                  lat: gpsLocation?.latitude,
                  lng: gpsLocation?.longitude,
                },
                {
                  ...profileData?.lastLocation,
                }
              )}km away`}</h6>
            </div>
          </div>
          {/* User age, sports and number of friends */}
          <div className="flex items-end justify-between w-[60%] ml-16">
            <div className="flex flex-col items-center">
              <h6 className="font-sans font-semibold text-lg">
                {profileData?.birthday
                  ? calculateAge(profileData?.birthday)
                  : '-'}
              </h6>
              <h6 className="font-sans font-medium">Age</h6>
            </div>
            <div className="flex flex-col items-center">
              <h6 className="font-sans font-semibold text-lg">
                {profileData?.friendsCount}
              </h6>
              <h6 className="font-sans font-medium">Friends</h6>
            </div>
            <div className="flex flex-col items-center">
              <h6 className="font-sans font-semibold text-lg">{totalSports}</h6>
              <h6 className="font-sans font-medium">Sports</h6>
            </div>
          </div>
        </div>
        {/* Description */}
        <div>
          <p className="lg:text-lg sm:max-lg:text-base font-sans text-racqy-settings-label font-light">
            {profileData?.bio}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
