import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import arrowRightIcon from '../assets/icons/arrow-right-icon.svg';
import Sporter from '../components/Sporter';
import FavouriteLocations from '../components/FavouriteLocations';
import Person from '../components/Person';
import UserDetail from '../components/UserDetail';
import { client } from '../api/racqy';
import { users } from '../api/api-requests';
import { auth } from '../api/firebase';
import UserDetailMobile from '../components/UserDetailMobile';
import SporterMobile from '../components/SporterMobile';
import FavouriteLocationsMobile from '../components/FavouriteLocationsMobile';
import useMobileView from '../hooks/useMobileView';
import { hideLoader } from '../utils/domSelector';
import Spinner from '../components/Spinner';
// import { useDispatch, useSelector } from 'react-redux';
// import { setLoginModalOpen } from '../redux/slices/bookingSlice';
// import { useAuthContext } from '../contexts/auth';

const UserProfile = ({ params, location }) => {
  const username = params['*'];

  // const redirectPath = `/u/${username}`;

  const mobileView = useMobileView('max', 639);

  // const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [userInterests, setUserInterests] = useState([]);
  const [otherPeopleViewed, setOtherPeopleViewed] = useState([]);
  const [userNotFound, setUserNotFound] = useState(false);

  // const { user, checked, isRedirectURL } = useAuthContext();

  // useEffect(() => {
  //   if (!user && checked) {
  //     if (isRedirectURL) {
  //       dispatch(setLoginModalOpen(true));
  //       navigate(`/?required_signin=true&redirect=${redirectPath}`);
  //     } else {
  //       dispatch(setLoginModalOpen(false));
  //       navigate('/');
  //     }
  //   }
  // }, [checked, user, isRedirectURL]);

  const fetchUserIDByUsername = async () => {
    try {
      const res = await client(users.getUserById(username));

      if (res?.data?.success) {
        setUserID(res?.data?.userId);
      }
    } catch (error) {
      if (error?.response?.data?.userNotFound) {
        setUserNotFound(true);
        setLoading(false);
        hideLoader();
      }
    }
  };

  useEffect(() => {
    fetchUserIDByUsername();
  }, [username]);

  const fetchUserDetail = async () => {
    try {
      const res = await client(users.getUserDetail(userID));

      if (res?.data?.success) {
        setProfileData(res?.data?.profileData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserInterests = async () => {
    try {
      let data = [];
      const res = await client(users.getUserInterests(userID, { profileData }));
      if (res?.data?.success) {
        res?.data?.userInterests.map((interest) =>
          data.push({
            level: interest?.sportsLevel[0]?.level,
            sport: interest?.sportsLevel[0]?.sport,
            diff: interest?.diff,
          })
        );
        setUserInterests(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOtherPeopleViewed = async () => {
    try {
      const res = await client(users.searchFriends('', userID));
      if (res?.data?.success) {
        let friends = [...res?.data?.friends];

        const sortedFriends = friends.sort(
          (a, b) => a.friendsCount + b.friendsCount
        );

        const filteredFriendsWithUsername = sortedFriends.filter(
          (friend) => friend?.id !== auth?.currentUser?.uid && friend?.username
        );

        setOtherPeopleViewed([...filteredFriendsWithUsername]);
      }
      setLoading(false);
      hideLoader();
    } catch (error) {
      console.log(error);
      setLoading(false);
      hideLoader();
    }
  };

  useEffect(() => {
    if (userID && !userNotFound) {
      fetchUserDetail();
    }
  }, [userID, userNotFound]);

  useEffect(() => {
    if (Object.keys(profileData).length > 0 && !userNotFound) {
      fetchOtherPeopleViewed();
      fetchUserInterests();
    }
  }, [profileData, userNotFound]);

  return (
    <Layout skipLoading={true} location={location}>
      <Helmet>
        <title>
          {profileData?.name?.full
            ? `${profileData?.name?.full} (@${profileData?.username}) | Racqy`
            : 'User Profile | Racqy'}
        </title>
      </Helmet>
      {loading ? (
        <div className="w-full h-full min-h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="w-full h-full min-h-screen py-8 px-6 mt-16">
          <img
            src={arrowRightIcon}
            className="cursor-pointer w-5 h-5 rotate-180"
            onClick={() => navigate('/')}
          />
          {userNotFound ? (
            <div className="h-[70vh] w-full flex items-center justify-center self-center">
              <h1>User not found 😔</h1>
            </div>
          ) : (
            // User info section
            <div className="w-full lg:pl-32 sm:max-lg:pl-10 mt-5 md:pr-16 sm:max-md:pr-10">
              {/* User detail section */}
              {mobileView ? (
                <UserDetailMobile
                  profileData={profileData}
                  totalSports={userInterests.length}
                />
              ) : (
                <UserDetail
                  profileData={profileData}
                  totalSports={userInterests.length}
                />
              )}
              {/* Sporter and Fav locations  */}
              {mobileView ? (
                <div className="flex flex-col mt-7">
                  <SporterMobile interests={userInterests} />
                  {profileData?.favouriteLocations.length > 0 && (
                    <FavouriteLocationsMobile
                      favouriteLocations={profileData?.favouriteLocations}
                    />
                  )}
                </div>
              ) : (
                <div className="flex items-start justify-between mt-10 md:w-[710px] sm:max-md:w-[600px]">
                  <Sporter interests={userInterests} />
                  <FavouriteLocations
                    favouriteLocations={profileData?.favouriteLocations}
                  />
                </div>
              )}
              <div className="mt-10">
                <h3 className="font-sans">Other people viewed</h3>
                <div className="w-full mt-4 pl-3 flex items-center flex-wrap">
                  {otherPeopleViewed.map((person) => (
                    <Person
                      person={person}
                      onClick={() => {
                        navigate(`/u/${person?.username}`);
                        setLoading(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default UserProfile;
