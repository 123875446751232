import React from 'react';
import './index.css';
import clubHouse from '../../images/icons/sports/club-house.svg';

const FavouriteLocationsMobile = ({ favouriteLocations }) => {
  return (
    <>
      <h6 className="font-sans mt-5">Favourite locations</h6>
      {favouriteLocations.map((favLocation) => (
        <div className="fav-card-border flex items-center justify-between overflow-hidden min-h-[56px] rounded-2xl mt-2 border-[1px] border-gray-300 relative flex-grow pr-2 py-1">
          <div className="flex items-center ml-3">
            <img src={clubHouse} className="svg-filter w-5 h-5" />
            <div className="flex flex-col items-start ml-2">
              <h6 className="font-sans text-xs">{favLocation?.name}</h6>
              <h6 className="font-sans text-[10px] text-racqy-text-light">
                {favLocation?.address}
              </h6>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FavouriteLocationsMobile;
