import React from 'react';
import '../../styles/svg.css';
import { getSportIcon } from '../../utils/racqy';
import menuDots from '../../assets/icons/menu-dots.svg';
import GetLocation from '../../hooks/getLocation';
import { calculateDistance } from '../../helper/racqyHelper';
import profileIcon from '../../assets/icons/profile-icon.svg';

const Person = ({ person, onClick }) => {
  const { gpsLocation } = GetLocation();
  return (
    <div
      className="max-sm:w-72 md:w-72 sm:max-md:w-60 h-28 flex items-center cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-col justify-center h-full w-[25%]">
        {person?.profilePictureStorageUrl ? (
          <img
            src={person?.profilePictureStorageUrl}
            className="max-sm:w-16 max-sm:h-16 md:w-16 md:h-16 sm:max-md:w-12 sm:max-md:h-12 rounded-full"
          />
        ) : (
          <div className="flex items-center justify-center bg-racqy-light-blue max-sm:w-16 max-sm:h-16 md:w-16 md:h-16 sm:max-md:w-12 sm:max-md:h-12 rounded-full">
            <img
              src={profileIcon}
              className="md:w-10 md:h-10 max-sm:w-10 max-sm:h-10 sm:max-md:w-7 sm:max-md:h-7"
            />
          </div>
        )}
      </div>
      <div className="ml-3 border-b-[1px] border-gray-200 w-[75%]">
        <div className="mb-2">
          <h6 className="font-sans text-xs font-semibold">
            {person?.username}
          </h6>
          <h6 className="font-sans text-[10px] text-racqy-text-light">
            {`${person?.name?.full}, ${calculateDistance(
              {
                lat: gpsLocation?.latitude,
                lng: gpsLocation?.longitude,
              },
              {
                ...person?.lastLocation,
              }
            )}km away`}
          </h6>
        </div>
        <div className="flex items-center mb-4">
          {person?.sports.length > 4 ? (
            <>
              {person?.sports.slice(0, 4).map((sport, index) => (
                <img
                  src={getSportIcon(sport)}
                  className={`svg-filter ${
                    sport === 'basketball' || sport === 'beach_soccer'
                      ? 'w-5 h-5'
                      : 'w-6 h-4'
                  } ${index > 0 && 'ml-1.5'}
              ${index === person?.sports.length - 1 && 'ml-2'}
              `}
                />
              ))}
              <img src={menuDots} className="w-4 ml-1" />
            </>
          ) : (
            person?.sports.map((sport, index) => (
              <img
                src={getSportIcon(sport)}
                className={`svg-filter ${
                  sport === 'basketball' || sport === 'beach_soccer'
                    ? 'w-5 h-5'
                    : 'w-6 h-4'
                } ${index > 0 && 'ml-1.5'}
              ${
                index === person?.sports.length - 1 &&
                person?.sports.length !== 1 &&
                'ml-2'
              }
              `}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Person;
