import React from 'react';
import './index.css';
import { formatSportsName, getSportIcon } from '../../utils/racqy';
import { calculateDifference, getLevelColor } from '../../helper/racqyHelper';

const Sporter = ({ interests }) => {
  return (
    <div className="w-[350px] h-[270px] overflow-scroll rounded-xl shadow-gray-100 shadow-lg px-3 py-3 flex-grow mr-12">
      <h6 className="font-sans">Sporter</h6>

      {interests?.length > 0 &&
        interests.map((interest) => (
          <div className="card-border flex items-center justify-between overflow-hidden h-14 rounded-2xl mt-4 border-[1px] border-gray-300 relative">
            {/* Sport img and name */}
            <div className="flex items-center ml-3">
              <img
                src={getSportIcon(interest?.sport)}
                className="sport-svg-filter w-7 h-7"
              />
              <h6 className="font-sans text-xs ml-1">
                {formatSportsName(interest?.sport)}
              </h6>
            </div>
            {/* Sport difficulty */}
            <div className="mr-3 flex items-center">
              <div
                className="w-7 h-5 rounded-md flex items-center justify-center mr-2"
                style={{
                  backgroundColor: getLevelColor(interest?.level).bg,
                }}
              >
                <h6 className="font-sans">{interest?.level}</h6>
              </div>
              <h6 className="text-[10px] text-racqy-text-light mx-2">
                {calculateDifference(interest?.diff)}
              </h6>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Sporter;
