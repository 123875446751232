import React, { useEffect, useState } from 'react';
import { isBrowser } from '../utils/isBrowser';

export default function GetLocation() {
  const [gpsLocation, setGpsLocation] = useState(null);
  const [attemptedFetchingGpsLocation, setAttemptedFetchingGpsLocation] =
    useState(false);

  useEffect(() => {
    if (!attemptedFetchingGpsLocation) {
      if (isBrowser()) {
        navigator.geolocation.getCurrentPosition((location) => {
          setGpsLocation({
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
          });
        });
        setAttemptedFetchingGpsLocation(true);
      }
    }
  }, []);

  return { gpsLocation, attemptedFetchingGpsLocation };
}
